import React, { useState } from "react";
import styled from "styled-components";
import Header from "./header";
import Footer from "./footer";
import Main from "./main";
import GlobalStyle from "../../styles/global";
import SEO from "../SEO";

const Container = styled.div`
  margin: 0 auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  Link {
    cursor: pointer;
  }
`;
const Layout = ({ children }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container>
      <SEO />
      <GlobalStyle />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
};

export default Layout;
