import React from "react";
import { Helmet } from "react-helmet";
import image from "../images/icon.svg";
import ChannelService from "./channelTalk";
import favicon from "../static/favicon.ico";
const SEO = () => {
  ChannelService.boot({
    pluginKey: "8f52da75-fb24-4bbe-a778-9dc348bbd4e8", //please fill with your plugin key
  });

  return (
    <Helmet htmlAttributes={{ lang: "ko" }}>
      <meta name="image" content={image} />
      <meta name="icon" href={favicon} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="샘랩" />
      <meta property="twitter:site" content="@samlab" />
      <meta property="twitter:creator" content="@samlab" />
      <meta property="twitter:domain" content="샘랩" />
      <link rel="shortcut icon" href={image} />
      <link rel="shortcut icon" href="https://samlab.co.kr/favicon.ico" />
      <link rel="icon" type="image/png" href={image} sizes="16x16" />
      <link rel="icon" href={image} />
      <link rel="canonical" href="https://samlab.co.kr" />
      <meta
        name="naver-site-verification"
        content="828c70afad7ae1f7211750a406c9cda71bbc10ae"
      />
      {/* <!-- Google analytics --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-87EJ1Z5S29"></script>
      <script>
       {` window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-87EJ1Z5S29');`}
      </script>
      {/* <!-- End Google analytics --> */}
    </Helmet>
  );
};

export default SEO;
