import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../images/logo.svg";
import logo_G from "../../images/logo_green.svg";
import colorLogo from "../../images/footer/colorLogo.svg";
import { Link } from "gatsby";
import { headerNotionUrl } from "../../data/url";

const Container = styled.div`
  position: relative;
  width: 100%;
  top: 0;
  z-index: 100;
  .header {
    width: 100%;
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    height: 4vw;

    .header-margin {
      z-index: 100;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 62%;
      margin: 0 auto;
      height: 100%;
      a {
        cursor: pointer;
      }
    }
    font-size: 0.8vw;
    img {
      width: 130px;
      margin-top: 10px;
    }
    .header-menu_List {
      color: #ffffff;
      display: flex;

      li {
        margin-left: 4vw;
        padding: 2vw 0;
        /* transition: border-bottom 0.2s ease-in-out; */
        :hover {
          padding: 2vw 0 1vw;
          border-bottom: 0.2vw solid #01c3c5;
        }
      }
    }
  }

  .color-header {
    z-index: 100;
    display: none;
    /* transition: background-color 0.3s ease-in-out;
    :hover { */
    /* background-color: #fff; */
    /* } */

    .header-menu_List {
      color: #222222;
    }
  }
  .hoverBar {
    /* border-top: 1px solid #f1f1f1; */
    z-index: 99;
    background-color: #fff;
    width: 100%;
    position: absolute;
    top: 0;
    height: 8vw;
    padding-top: 4.6vw;
    width: 100%;
    border-top: 1px solid #aaaaaa;
    li {
      font-size: 0.8vw;
      margin: 0.5vw 0 1.2vw 55.3vw;
      text-align: start;
      color: #aaaaaa;
      :hover {
        color: #222;
        margin-left: 55.3vw;
        cursor: pointer;
      }
    }
  }
  .line {
    position: absolute;
    top: 3.6vw;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #f1f1f1;
    z-index: 999;
  }
  .show-460 {
    display: none;
  }

  .header.drop {
    position: fixed;
    margin-top: 0;
    animation: dropHeader 0.3s;
  }

  @keyframes dropHeader {
    0% {
      top: -4vw;
    }
    100% {
      top: 0;
    }
  }

  .scroll-header {
    display: flex;
    margin-top: -4vw;
    background-color: rgb(255, 255, 255);
    .hoverBar {
      display: none;
    }
  }

  @media screen and (max-width: 1210px) {
    .hoverBar {
      li {
        margin: 0.5vw 0 1.2vw 65vw;
        :hover {
          margin-left: 65.2vw;
        }
      }
    }
  }
  @media screen and (max-width: 1110px) {
    .hoverBar {
      li {
        margin: 0.5vw 0 1.2vw 64vw;
        :hover {
          margin-left: 64.2vw;
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .hoverBar {
      li {
        margin: 0.5vw 0 1.2vw 63vw;
        :hover {
          margin-left: 63.2vw;
        }
      }
    }
  }
  @media screen and (max-width: 1020px) {
    .hide-460 {
      display: none !important;
    }

    .show-460 {
      display: block;
    }

    .header_mobile {
      position: absolute;
      width: 88%;
      padding: 2vw 6vw 0;
      background-color: transparent;
      z-index: 10;
      img {
        width: 120px;
      }
      .menu {
        img {
          width: 120px;
          margin: 2vw 0 0 6vw;
        }
        div {
          width: 100vw;
          margin-top: 20vh;
          .mobile_submenu {
            display: none;
            p {
              font-size: 3vw;
              color: #aaaaaa;
            }
          }
          p {
            font-size: 4vw;
            margin: 6vw 6vw;
          }
        }
      }
    }

    .show_submenu {
      display: flex !important;
      flex-direction: column;
      margin-top: 0 !important;

      p {
        margin: 2vw 0 2vw 6vw !important;
      }
    }

    .menu {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 100vh;
      max-width: 0;
      transition: 0.5s ease;
      z-index: 1;
      background-color: #eee;
    }

    .burger-icon {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      z-index: 2;
      padding: 2.4vw 6vw;
      top: 4px;
      right: 4px;
      user-select: none;
      width: auto;
      margin: 0;
    }

    .burger-icon .burger-sticks {
      background: #fff;
      display: block;
      height: 2px;
      position: relative;
      transition: background 0.2s ease-out;
      width: 18px;
    }

    .burger-icon .burger-sticks:before,
    .burger-icon .burger-sticks:after {
      background: #fff;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;
    }

    .burger-icon .burger-sticks:before {
      top: 5px;
    }

    .burger-icon .burger-sticks:after {
      top: -5px;
    }

    .burger-check {
      display: none;
    }

    .burger-check:checked ~ .menu {
      max-width: 100%;
      min-height: 100vh;
      display: block;
    }

    .burger-check:checked ~ .burger-icon .burger-sticks {
      background: transparent;
    }

    .burger-check:checked ~ .burger-icon .burger-sticks:before {
      transform: rotate(-45deg);
      background: #222;
    }

    .burger-check:checked ~ .burger-icon .burger-sticks:after {
      transform: rotate(45deg);
      background: #222;
    }

    .burger-check:checked ~ .burger-icon:not(.steps) .burger-sticks:before,
    .burger-check:checked ~ .burger-icon:not(.steps) .burger-sticks:after {
      top: 0;
    }
    .burger-icon .burger-dark,
    .burger-icon .burger-dark:before,
    .burger-icon .burger-dark:after {
      background: #222;
    }
  }
  @media screen and (max-width: 460px) {
    .header_mobile {
      padding: 6vw 6vw 0;

      img {
        width: 28.8vw;
      }
      .menu {
        width: 100vw;

        img {
          width: 28.8vw;
          margin: 6vw 0 0 6vw;
        }
        div {
          .mobile_submenu {
            display: none;

            p {
              font-size: 5.5vw;
              color: #aaaaaa;
            }
          }
          p {
            font-size: 8vw;
          }
        }
      }
    }
    .burger-icon {
      padding: 6.6vw 6vw;
    }
  }
  @media screen and (max-width: 360px) {
    .header_mobile {
      .menu {
        img {
          width: 28.2vw;
        }
      }
    }
  }
`;

const Header = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  let header;
  let navbar;
  let burgercheck;
  let location;
  let scrollHeader;

  if (typeof window !== "undefined") {
    location = window.location.href;
    header = document.getElementsByClassName("header");
    navbar = document.getElementsByClassName("hoverBar");
    burgercheck = document.getElementsByTagName("body")[0];
    scrollHeader = document.querySelector(".scroll-header");
  }

  const updateScroll = (event) => {
    let windowTop = window.scrollY;
    let docHeight = document.body.offsetHeight;
    let winHeight = window.innerHeight;
    let scrollPercent = windowTop / (docHeight - winHeight);
    let scrollPercentRounded = Math.round(scrollPercent * 100);
    setScrollPosition(scrollPercentRounded);
    if (scrollHeader !== "undefined" && scrollHeader !== null) {
      if (event.deltaY > 0) {
        scrollHeader.classList.remove("drop");
        header[3].style.display = "none";
      } else if (windowTop < 100) {
        scrollHeader.classList.remove("drop");
        header[3].style.display = "none";
      } else {
        scrollHeader.classList.add("drop");
        header[3].style.display = "flex";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", updateScroll);
  }, [scrollPosition]);

  useEffect(() => {
    burgercheck.style.overflowY = "auto";
    if (
      location.split("/")[3] === "newsroom" ||
      location.split("/")[3] === "product-spec"
    ) {
      header[0].style.display = "none";
      header[1].style.display = "flex";
    } else {
      header[0].style.display = "flex";
      header[1].style.display = "none";
    }
  }, [location]);

  const handleHover = () => {
    navbar[0].style.display = "block";
    header[0].style.display = "none";
    header[1].style.display = "none";
    header[2].style.display = "flex";
  };

  // 스크롤 헤더
  const handleHoverS = () => {
    navbar[0].style.display = "none";
    navbar[1].style.display = "block";
    header[0].style.display = "none";
    header[1].style.display = "none";
    header[2].style.display = "flex";
  };

  const handleLeave = () => {
    if (
      location.split("/")[3] === "newsroom" ||
      location.split("/")[3] === "product-spec"
    ) {
      navbar[0].style.display = "none";
      header[0].style.display = "none";
      header[1].style.display = "flex";
      header[2].style.display = "none";
    } else {
      navbar[0].style.display = "none";
      header[0].style.display = "flex";
      header[1].style.display = "none";
      header[2].style.display = "none";
    }
  };
  // 스크롤 헤더
  const handleLeaveS = () => {
    navbar[0].style.display = "none";
    navbar[1].style.display = "none";
    header[0].style.display = "flex";
    header[1].style.display = "none";
    header[2].style.display = "none";
  };

  //모바일 배열
  const arr = ["newsroom", "product"];
  // WHAT WE DO 모바일 li 표시 기능
  const clickHamberger = (event) => {
    event.target.nextSibling.classList.toggle("show_submenu");
  };
  //모바일
  const bodyfix = () => {
    if (burgercheck.style.overflowY !== "hidden")
      burgercheck.style.overflowY = "hidden";
    else burgercheck.style.overflowY = "auto";
  };

  return (
    <Container>
      <div className="header hide-460">
        <div onMouseOver={handleHover} className="header-margin">
          <Link to="/">
            <img src={logo} />
          </Link>
          <ul className="header-menu_List">
            <Link to="/aboutus">
              <li>ABOUT US</li>
            </Link>
            <Link to="/letmeup">
              <li>WHAT WE DO</li>
            </Link>
            <Link to="/newsroom">
              <li>NEWSROOM</li>
            </Link>
            <a href={headerNotionUrl.RecuitMember} target={'_blank'}>
              <li>RECRUIT MEMBER</li>
            </a>
          </ul>
        </div>
        {/* <ul className="hoverBar">
          <li>Platform business</li>
          <li>Cloud customer solution</li>
          <li>space business</li>
        </ul> */}
      </div>
      <div className="header color-header hide-460">
        <div className="header-margin">
          <Link to="/">
            <img src={colorLogo} />
          </Link>
          <ul className="header-menu_List">
            <Link to="/aboutus">
              <li>ABOUT US</li>
            </Link>
            <Link to="/letmeup">
              <li>WHAT WE DO</li>
            </Link>
            <Link to="/newsroom">
              <li>NEWSROOM</li>
            </Link>
            <a href={headerNotionUrl.RecuitMember} target={'_blank'}>
              <li>RECRUIT MEMBER</li>
            </a>
          </ul>
        </div>
        {/* <ul className="hoverBar">
          <li>Platform business</li>
          <li>Cloud customer solution</li>
          <li>space business</li>
        </ul> */}
      </div>
      <div onMouseLeave={handleLeave} className="header color-header hide-460">
        <div className="header-margin">
          <Link to="/">
            <img src={colorLogo} />
          </Link>
          <ul className="header-menu_List">
            <Link to="/aboutus">
              <li>ABOUT US</li>
            </Link>
            <Link to="/letmeup">
              <li>WHAT WE DO</li>
            </Link>
            {/* <li>TECH STORY</li> */}
            <Link to="/newsroom">
              <li>NEWSROOM</li>
            </Link>
            <a href={headerNotionUrl.RecuitMember} target={'_blank'}>
              <li>RECRUIT MEMBER</li>
            </a>
            {/* <li>IR</li> */}
          </ul>
        </div>
        <div className="line" />
        <ul className="hoverBar">
          <Link to="/letmeup">
            <li>Ai Platform Business</li>
          </Link>
          <a href={headerNotionUrl.EduCloudPlatform} target={'_blank'}>
            <li>Edu Cloud Platform</li>
          </a>
          <Link to="/spacebusiness">
            <li>Edu Space Businesss</li>
          </Link>
        </ul>
      </div>
      {/*모바일 헤더*/}
      <div className="show-460 header_mobile">
        <Link to="/">
          <img src={arr.some((el) => location?.includes(el)) ? logo_G : logo} />
        </Link>
        <input className="burger-check" type="checkbox" id="burger-check" />
        <label className="burger-icon" for="burger-check">
          <span
            onClick={bodyfix}
            className={
              arr.some((el) => location?.includes(el))
                ? "burger-sticks burger-dark"
                : "burger-sticks"
            }
          ></span>
        </label>
        <div className="menu">
          <img src={logo_G} />
          <div>
            <Link to="/aboutus">
              <p>ABOUT US</p>
            </Link>
            <p onClick={clickHamberger}>WHAT WE DO</p>
            <div className="mobile_submenu">
              <Link to="/letmeup">
                <p>AI PLATFORM BUSUNESS</p>
              </Link>
              <a href={headerNotionUrl.EduCloudPlatform} target={'_blank'}>
                <p>EDU CLOUD PLATFORM</p>
              </a>
              <Link to="/spacebusiness">
                <p>EDU SPACE BUSINESS</p>
              </Link>
            </div>
            {/* <p>TECH STORY</p> */}
            <Link to="/newsroom">
              <p>NEWSROOM</p>
            </Link>
            <a href={headerNotionUrl.RecuitMember}>
              <p>RECRUIT MEMBER</p>
            </a>
            {/* <p>IR</p> */}
          </div>
        </div>
      </div>
      {/* 스크롤시 보이는 header */}
      <div
        onMouseOver={handleHoverS}
        className="header color-header hide-460 scroll-header"
      >
        {scrollPosition > 0 ? (
          <>
            <div className="header-margin">
              <Link to="/">
                <img src={colorLogo} />
              </Link>
              <ul className="header-menu_List">
                <Link to="/aboutus">
                  <li>ABOUT US</li>
                </Link>
                <Link to="/letmeup">
                  <li>WHAT WE DO</li>
                </Link>
                {/* <li>TECH STORY</li> */}
                <Link to="/newsroom">
                  <li>NEWSROOM</li>
                </Link>
                <a href={headerNotionUrl.RecuitMember} target={'_blank'}>
                  <li>RECRUIT MEMBER</li>
                </a>
                {/* <li>IR</li> */}
              </ul>
            </div>
            <div onMouseLeave={handleLeaveS} className="header hide-460">
              <div className="header-margin">
                <Link to="/">
                  <img src={colorLogo} />
                </Link>
                <ul className="header-menu_List">
                  <Link to="/aboutus">
                    <li>ABOUT US</li>
                  </Link>
                  <Link to="/letmeup">
                    <li>WHAT WE DO</li>
                  </Link>
                  {/* <li>TECH STORY</li> */}
                  <Link to="/newsroom">
                    <li>NEWSROOM</li>
                  </Link>
                  {/* <li>IR</li> */}
                  <a href={headerNotionUrl.RecuitMember} target={'_blank'}>
                    <li>RECRUIT MEMBER</li>
                  </a>
                </ul>
              </div>
              <div className="line" />
              <ul className="hoverBar">
                <Link to="/letmeup">
                  <li>Ai Platform Business</li>
                </Link>
                <a href={headerNotionUrl.EduCloudPlatform} target={'_blank'}>
                  <li>Edu Cloud Platform</li>
                </a>
                <Link to="/spacebusiness">
                  <li>Edu Space Businesss</li>
                </Link>
              </ul>
            </div>
          </>
        ) : null}
      </div>
    </Container>
  );
};

export default Header;
