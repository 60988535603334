import React, { useEffect } from "react";
import styled from "styled-components";
import colorLogo from "../../images/footer/colorLogo.svg";
import facebook from "../../images/footer/Facebook.svg";
import instargram from "../../images/footer/Instagram.svg";
import youtube from "../../images/footer/Youtube.svg";
import facebook_h from "../../images/footer/Facebook_hover.svg";
import instargram_h from "../../images/footer/Instagram_hover.svg";
import youtube_h from "../../images/footer/Youtube_hover.svg";


import iconNaver from '../../images/footer/icn-naver.svg'
import iconBlog from '../../images/footer/icn-blog.svg'
import iconFacebook from '../../images/footer/icn-facebook.svg'
import iconInstagram from '../../images/footer/icn-instagram.svg'
import iconTwitter from '../../images/footer/icn-twitter.svg'


const Container = styled.div`
  .default-footer {
    width: 80%;
    height: 11.458vw;
    font-family: "Pretendard-Regular";
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    /* border-top: 1px solid black; */
    font-size: 0.8vw;
  }
  .logo {
    width: 7vw;
  }
  .textPart {
    display: flex;
    flex-direction: column;
    /* height: 60px; */
    justify-content: space-between;
    p {
      :not(:last-child) {
        margin-bottom: 0.8vw;
      }

      line-height: 1.5;
    }
  }

  .number {
    display: flex;
    flex-direction: column;
    height: 4.427vw;
    justify-content: space-evenly;
    p {
      margin-bottom: 0.2vw;
    }
    span {
      font-size: 1.4vw;
      font-family: "Pretendard-ExtraBold";
    }
  }

  .followUs {
    ul{
      .hidden{
        display: none;
      }
      display: flex;
      gap: 1rem;
      >li{
        width: 100%;
       > img {
        width:37px;
        height: 36px;
        }
      }
    }
    p {
      margin-bottom: 1vw;
    }
   
  }



  .mobile {
    display: none;
  }
  .pc {
    display: block;
  }
  @media screen and (max-width: 1020px) and (orientation: portrait) {
    .default-footer {
      width: 90%;
      height: 47vw;
      flex-direction: column;
      align-items: flex-start;
    }

    .logo {
      width: 17vw;
      margin: 2vw 0;
    }

    .textPart {
      p {
        font-size: 2vw;
        :not(:last-child) {
          margin-bottom: 1vw;
        }
      }
    }

    .number {
      height: 7vh;
      margin: 2vw 0;
      line-height: 1.4;
      p {
        font-size: 2.2vw;
      }
      span {
        font-size: 3vw;
      }
    }

    .followUs {
      ul{
      .hidden{
        display: none;
      }
      display: flex;
      gap: 1rem;
      >li{
        width: 100%;
       > img {
        width:37px;
        height: 36px;
        }
      }
    }
    p {
      margin-bottom: 1vw;
    }
    }

    

    .mobile {
      display: block;
      span {
        margin-left: 3vw;
      }
    }
    .pc {
      display: none;
    }
  }

  @media screen and (max-width: 460px) {
    .default-footer {
      width: 90%;
      height: 90vw;
      flex-direction: column;
      align-items: flex-start;
    }

    .logo {
      width: 23vw;
      margin: 11vw 0;
    }

    .textPart {
      p {
        font-size: 3.7vw;
        :not(:last-child) {
          margin-bottom: 3vw;
        }
      }
    }

    .number {
      height: 7vh;
      margin: 4vw 0;
      line-height: 1.5;
      p {
        font-size: 3.2vw;
      }
      span {
        font-size: 5vw;
      }
    }

    .followUs {
      p {
        font-size: 3.2vw;
      }
      ul{
      .hidden{
        display: none;
      }
      display: flex;
      gap: 1rem;
      >li{
        width: 100%;
       > img {
        width:37px;
        height: 36px;
        }
      }
    }
    }

    
    .mobile {
      display: block;
      span {
        margin-left: 3vw;
      }
    }
    .pc {
      display: none;
    }
  }
`;


const socialIconList  =[
  {
      "name":"icn-naver",
      "src":`${iconNaver}`,
      "aria-label":"Classup Naver",
      "link":"",
      "isShow":false
  },
  {
      "name":"icn-naver-blog",
      "src":`${iconBlog}`,
      "label":"ClassUp Blog",
      "link":"https://blog.naver.com/saemlab",
      "isShow":true
  },
  {
      "name":"icn-facebook",
      "src":`${iconFacebook}`,
      "aria-label":"ClassUp FaceBook",
      "link":"https://www.facebook.com/profile.php?id=100088863205554",
      "isShow":false

  },
  {
      "name":"icn-instagram",
      "src":`${iconInstagram}`,
      "aria-label":"ClassUp Instagram",
      "link":"https://www.instagram.com/samlab_official/?hl=ko",
      "isShow":true

  },
  {
      "name":"icn-twitter",
      "src":`${iconTwitter}`,
      "aria-label":"ClassUp Twitter",
      "link":"https://twitter.com/classup_offical",
      "isShow":false

  }
  ]

const Footer = () => {
  const handleImgF = (e) => {
    e.target.src = facebook_h;
  };

  const handleImgI = (e) => {
    e.target.src = instargram_h;
  };

  const handleImgY = (e) => {
    e.target.src = youtube_h;
  };

  const handleImgOutF = (e) => {
    e.target.src = facebook;
  };

  const handleImgOutI = (e) => {
    e.target.src = instargram;
  };

  const handleImgOutY = (e) => {
    e.target.src = youtube;
  };

  // const handleImgOutFW = (e) => {
  //   e.target.src = facebook_w;
  // };

  // const handleImgOutIW = (e) => {
  //   e.target.src = instargram_w;
  // };

  // const handleImgOutYW = (e) => {
  //   e.target.src = youtube_w;
  // };

  // let footer;
  // let location;
  // if (typeof window !== "undefined") {
  //   footer = document.getElementsByClassName("footer");
  // }

  // useEffect(() => {
  //   location = window.location.href;
  //   if (
  //     location.split("/")[3] === "product-spec" ||
  //     location.split("/")[3] === "samkiosk"
  //   ) {
  //     footer[0].style.display = "none";
  //     footer[1].style.display = "flex";
  //   } else {
  //     footer[0].style.display = "flex";
  //     footer[1].style.display = "none";
  //   }
  // }, []);

  return (
    <Container>
      <div className="default-footer footer">
        <img className="logo" src={colorLogo} />
        <div className="textPart">
          <p>대표 : 심세용</p>
          <p>경기도 시흥시 은계중앙로 362 수림프라자 902호,1001호</p>
          {/* <p>
            기업부설연구소 <br /> 경기도 시흥시 은계번영길 2 서미프라자 601호 602호
          </p> */}
          <p>samlab@samlab.co.kr</p>
        </div>
        <div className="number">
          <p>TEL</p>
          <span className="customer-service-number">
            <a href="tel:1688-4264">1688-4264</a>
          </span>
        </div>
        <div className="followUs">
          <p>FOLLOW US</p>
          <ul>
          {socialIconList.map(item => <li className={item.isShow? '':'hidden'}>
            < a aria-label={item.label} target={'_blink'} href={item.link}>
              <img style={{'width':'25px'}} src={item.src} alt={item.alt}/>
            </a>
          </li>)}

          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
