//노션 url
export const headerNotionUrl ={
  EduCloudPlatform:'https://samlab.oopy.io/',
  RecuitMember:'https://www.notion.so/samlab/RECRUIT-MEMBER-9247b92f411c4b6d959865d2ab0b8515'
}
//노션 url
export const mainNotionUrl ={
  Letmeup:'https://www.notion.so/samlab/Letmeup-340ec0e238614f0d84413325a2d54e87',
  Classup:'https://www.notion.so/samlab/Classup-91deb24978e8488ea1b06ba6c30eb2e2',
  Schoolup:'https://www.notion.so/samlab/Schoolup-f951166715df4475890c6ab27e1f4ffd'
}